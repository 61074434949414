<template>
 
  <router-view />

  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/adav1privacy">Gritty Privacy</router-link> |
    <router-link to="/adav1support">Gritty Support</router-link> |
    <router-link to="/adav1eula">Gritty EULA</router-link>
  </nav>

</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 180px;
}

nav {
  position: fixed;
  bottom: 0;

  /* And if you want the div to be full-width: */
  left: 0;
  right: 0;
  padding: 50px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
